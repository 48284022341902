import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Advance Fluvial Hydraulics`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1_KTptzxUFBS1HtK1F_IlnwrJZlURha11"
      }}>{`Download: Flow in open channels by K. Subramanya 3rd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1ShIDVxmvp6sxON4L8c7l93WRNmEGi1dB"
      }}>{`Download: Civil engineering hydraulics by R.E. Featherstone and C. Nalluri`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1oK-ZAM_GrTS-C0vhkuuOQrOF30G563lK"
      }}>{`Download: Computational hydraulics by Indian institute of science `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1TtvAT9wxvIrw7gkMnzfNgk7eBh2qEHIh"
      }}>{`Download: Fundamentals of open channel flow by Glenn E. Moglen `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1XitBzzy3IY3380Z5TaUT6FxlmHX-4VmI"
      }}>{`Download: Numerical modelling in open channel hydraulics by Romuald Szymkiewicz`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1HjePyCM1ztLmZnL2QU98f3q9LP866jdb"
      }}>{`Download: The hydraulics of open channel flow an introduction by Hubert Chanson 2nd ed. `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1_20OkWwvddAqFALAUEg6Hu72U8KPHFcV"
      }}>{`Download: Open channel flow by M. Hanif Chaudhry 2nd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1E8yrc-CScD6eD52E8k3MkvJNoHFo5uXj"
      }}>{`Download: Open channel flow by F.M. Henderson `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1ZON5BpfD1tS72Tlgw4LL4pQLxW5mgfnn"
      }}>{`Download: Open channel hydraulics by Ven Te Chow`}</a></p>
    <h2>{`Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/advection-dispersion-equations.pdf"
      }}>{`advection dispersion equations`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Channel-Morphology.ppt"
      }}>{`Channel Morphology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Models-and-similarity.ppt"
      }}>{`Models and similarity`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/non-uniform-flow-direct-integration.ppt"
      }}>{`non uniform flow-direct integration`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/non_uniform_flow.ppt"
      }}>{`non_uniform_flow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/Surges-in-channels.ppt"
      }}>{`Surges in channels`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/03/unsteady-Flow.ppt"
      }}>{`unsteady Flow`}</a></p>
    <h3>{`Assignment Problem:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/download/problem-solution-euler-method-modified-euler-method-4th-order-rk-method/"
      }}>{`Euler method, Modified Euler method, 4th order RK Method`}</a></p>
    <h2>{`Lab experiments`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/study-characteristics-of-flow-on-a-plane-and-gravel-bed/"
      }}>{`Study characteristics of flow on a plane and gravel bed`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/to-study-flow-characteristics-through-venturi-flume/"
      }}>{`To study flow characteristics through venturi flume`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/to-study-flow-characteristics-through-parshall-flume/"
      }}>{`To study flow characteristics through Parshall flume`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/to-study-water-surface-profiles-on-mild-slope/"
      }}>{`To study water surface profiles on mild slope`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/to-study-water-surface-profiles-on-steep-slope/"
      }}>{`To study water surface profiles on steep slope`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      